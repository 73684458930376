import React, { useState } from 'react';
import './navbar.css';
import logoImage from './xmasguy_character/logo.jpg'; // Update with the actual file name

function Navbar({ homeRef, buyRef, aboutRef }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling the hamburger menu

  const handleMemeGeneratorClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const scrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false); // Close the menu after clicking a link
  };

  return (
    <div>
      <header className="header">
        <div className="logo">
          <img
            src={logoImage}
            alt="XMASGUY Logo"
            className="logo-image"
          />
          <h1>XMASGUY CTO</h1>
        </div>
        <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
          <a
            href="#home"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(homeRef);
            }}
          >
            Home
          </a>
          <a
            href="#buy"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(buyRef);
            }}
          >
            Buy
          </a>
          <a
            href="#about"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(aboutRef);
            }}
          >
            About
          </a>
          <a
            href="#meme-generator"
            className="highlighted-link"
            onClick={handleMemeGeneratorClick}
          >
            Meme Generator
          </a>
        </nav>

        {/* Hamburger Button */}
        <div
          className="hamburger"
          onClick={() => setIsMenuOpen((prev) => !prev)}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </header>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <h2>App Coming Soon!</h2>
            <p>A utility app made for XMASGUY will be live shortly. Stay tuned!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
