import React, { useRef, useState, useEffect } from 'react';
import Navbar from './navbar';

import './website.css';

import logoImage from './xmasguy_character/logo.jpg'; // Update with actual file name
import chillGuyImage from './xmasguy_character/11.webp'; // Update with actual file name
import walletImage from './xmasguy_character/6.webp'; // Replace with the actual image for wallet step
import dexImage from './xmasguy_character/4.webp'; // Replace with the actual image for dex step
import swapImage from './xmasguy_character/8.webp'; // Replace with the actual image for swap step
import chillGuyImageAbout from './xmasguy_character/10.webp'; // Replace with actual image file

import dexScreenerIcon from './logos/dexscreener.png'; // Replace with actual image file
import dexToolsIcon from './logos/dextools.jpg'; // Replace with actual image file
import photonIcon from './logos/photon.png'; // Replace with actual image file
import birdeyeIcon from './logos/birdeye.png'; // Replace with actual image file
import rugCheckIcon from './logos/rugcheck.jpg'; // Replace with actual image file
import coinGeckoIcon from './logos/coinegecko.png'; // Replace with actual image file
import cmcIcon from './logos/cmc.png'; // Replace with actual image file
import pumpfunIcon from './logos/pumpfun.jpg'; // Replace with actual image file
import telegram from './logos/telegram.webp'; // Replace with actual image file


function Website() {
  // Create refs for each section
  const homeRef = useRef(null);
  const buyRef = useRef(null);
  const aboutRef = useRef(null);

   // State for the shortened contract address
   const [shortenedAddress, setShortenedAddress] = useState("EKpnpU33E9vnoc8ApNZZFPCv82zYZ6w7gDrkJfq1pump");
   const fullAddress = "EKpnpU33E9vnoc8ApNZZFPCv82zYZ6w7gDrkJfq1pump";
 
   // Dynamic truncation of the contract address
   useEffect(() => {
     const handleResize = () => {
       const screenWidth = window.innerWidth;
 
       if (screenWidth < 600) {
         setShortenedAddress(`${fullAddress.slice(0, 6)}...${fullAddress.slice(-4)}`);
       } else if (screenWidth < 768) {
         setShortenedAddress(`${fullAddress.slice(0, 10)}...${fullAddress.slice(-6)}`);
       } else {
         setShortenedAddress(fullAddress);
       }
     };
 
     handleResize(); // Initial call
     window.addEventListener('resize', handleResize);
 
     return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
   }, [fullAddress]);
 
   const copyToClipboard = () => {
     navigator.clipboard.writeText(fullAddress);
     alert("Contract Address Copied to Clipboard!");
   };


  return (
    <div className='mainsection'>

    <Navbar homeRef={homeRef} buyRef={buyRef} aboutRef={aboutRef} />


    <div className="container">




      <main ref={homeRef} className="main-content">
        <div className="toptext">
          <h2>Hi I’m just a chill xmas guy!</h2>
          <p>
            Our dev might have bailed, but the community didn’t! XMASGUY is all
            about believers who know that sometimes, holding strong is all it
            takes. Christmas Chill Guy is a meme character who’s all about being
            super relaxed, not caring much, and just waiting for Christmas. Join
            the vibe, and let’s make this comeback story one for the books!
          </p>
          <button
  className="buy-button"
  onClick={() =>
    window.open(
      'https://jup.ag/swap/SOL-EKpnpU33E9vnoc8ApNZZFPCv82zYZ6w7gDrkJfq1pump',
      '_blank',
      'noopener,noreferrer'
    )
  }
>
  Buy $XMASGUY
</button>

        </div>
        <img
          src={chillGuyImage}
          alt="Chill Xmas Guy"
          className="chill-guy-image"
        />
      </main>

      {/* Section 2: How to Buy */}
      <div ref={buyRef} className="section2">
        <h2>How to buy?</h2>
        <p className="section-description">
          Ready to ape in and join the chillest holiday crew in crypto? Follow these steps to snag your XMASGUY!
        </p>
        <div className="steps-container">
          <div className="step">
            <div className='stepbox'>
            <img src={walletImage} alt="Set Up Wallet" className="step-image" />
            <h3>Set Up Your Wallet</h3>
            <p>
              Download a Solana-compatible wallet like Phantom. Buy Solana (SOL)
              on an exchange (like Binance or Coinbase) and transfer it to your
              wallet.
            </p>
            </div>
            <button className='setup' onClick={() =>
    window.open(
      'https://phantom.com/',
      '_blank',
      'noopener,noreferrer'
    )
  }>GET PHANTOM</button>
          </div>
          <div className="step">
          <div className='stepbox'>
            <img src={dexImage} alt="Connect to DEX" className="step-image" />
            <h3>Connect to a DEX</h3>
            <p>
              Visit a Solana-supported DEX like Raydium or Jupiter. Connect your
              wallet by following the on-screen instructions.
            </p>
            </div>
            <button className='setup' onClick={() =>
    window.open(
      'https://jup.ag/swap/SOL-EKpnpU33E9vnoc8ApNZZFPCv82zYZ6w7gDrkJfq1pump',
      '_blank',
      'noopener,noreferrer'
    )
  }>BUY NOW</button>
          </div>
          <div className="step">
          <div className='stepbox'>
            <img src={swapImage} alt="Swap for XMASGUY" className="step-image" />
            <h3>Swap for XMASGUY</h3>
            <p>
              Use the contract address to find XMASGUY. Swap your SOL for
              XMASGUY, confirm the transaction, and you're done!
            </p>
            </div>
            <button className='setup' onClick={() =>
    window.open(
      'https://jup.ag/swap/SOL-EKpnpU33E9vnoc8ApNZZFPCv82zYZ6w7gDrkJfq1pump',
      '_blank',
      'noopener,noreferrer'
    )
  }>SWAP NOW</button>
          </div>
        </div>
      </div>

      <div  ref={aboutRef} className="section3">
        <div className='sectionflex'>
        <h2>About $XMASGUY</h2>
        <div className="about-content">
          <div className="about-text">
            <p>
              Meet XMASGUY, the chillest holiday hero! 🥶🎅 This festive dude is all about
              spreading good vibes and keeping it cool, no matter what. Whether it’s snowy
              nights or crypto chaos, XMASGUY reminds us to stay chill and enjoy the ride.
            </p>
            <p>
              Our community is the real gift that keeps on giving! Join us to connect with
              fellow believers, share in the festive cheer, and be part of something truly
              special this holiday season. 🎄
            </p>
            <p><b>Ticker Symbol:</b> XMAS</p>
            <div className="contract-address">
           
            <div className='contracttoken'> 
            <b>Contract Address:</b>
             {shortenedAddress}
              <i className="fa-solid fa-copy copy-icon" onClick={copyToClipboard} title="Copy to Clipboard"></i>
              </div>
            </div>
            <div className="about-buttons">
            <button
  className="follow-button"
  onClick={() =>
    window.open(
      'https://x.com/xmasguy_CTO?t=lGWUM4hDI-X8uSEDpbwrjQ&s=09',
      '_blank',
      'noopener,noreferrer'
    )
  }
>
  Follow us on X
</button>

<button
  className="telegram-button"
  onClick={() =>
    window.open(
      'https://t.me/xmasguycto2',
      '_blank',
      'noopener,noreferrer'
    )
  }
>
  Join our Telegram   <img
            src={telegram}
            alt="Telegram"
            className="telegram"
          />
</button>

<button
  className="telegram-button"
  onClick={() =>
    window.open(
      'https://t.me/xmasguycto2',
      '_blank',
      'noopener,noreferrer'
    )
  }
>
Comment on PumpFun   <img
            src={pumpfunIcon}
            alt="PumpFun"
            className="telegram"
          />
</button>

            </div>
          </div>
          </div>
          </div>
          <img
            src={chillGuyImageAbout}
            alt="Chill Xmas Guy"
            className="about-image"
          />
   
      </div>



      <div className="section4">
        <div className='section4flex'>
        <h2>Track XMASGUY</h2>
        <p className="section4-description">
          Stay updated on XMASGUY’s performance and ensure your investments are on track with these trusted tools. Whether you’re checking price charts, liquidity, or transaction data, these platforms have you covered.
        </p>
        </div>
        <div className="tools-container">
          <div className="tool">
            <img src={dexScreenerIcon} alt="DEX Screener" />
            <p>DEX Screener</p>
          </div>
          <div className="tool">
            <img src={dexToolsIcon} alt="DEX Tools" />
            <p>DEX Tools</p>
          </div>
          <div className="tool">
            <img src={photonIcon} alt="Photon" />
            <p>Photon</p>
          </div>
          <div className="tool">
            <img src={rugCheckIcon} alt="RugCheck" />
            <p>RugCheck</p>
          </div>
          <div className="tool">
            <img src={coinGeckoIcon} alt="CoinGecko" />
            <p>CoinGecko</p>
          </div>
          <div className="tool">
            <img src={cmcIcon} alt="CMC" />
            <p>CMC</p>
          </div>
          <div className="tool">
            <img src={pumpfunIcon} alt="PumpFun" />
            <p>PumpFun</p>
          </div>
        </div>
 
      </div>




    </div>
    </div>
  );
}

export default Website;
