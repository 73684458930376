import React from 'react';
import Navbar from './navbar';
import Website from './website';
import './App.css'; // Ensure you have your CSS linked here

function App() {
  return (
    <div className="App">
      <div className="bubbles-container">
        <div className="bubble bubble1"></div>
        <div className="bubble bubble2"></div>
        <div className="bubble bubble3"></div>
        <div className="bubble bubble4"></div>
        <div className="bubble bubble5"></div>
      </div>
      <Website />
    </div>
  );
}

export default App;
